export const login = (userData, userId) => ({
  type: 'LOGIN',
  idUser: userId,
  payload: userData
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const updateUserData = (userData) => ({
  type: 'UPDATE_USER_DATA',
  payload: userData,
});
