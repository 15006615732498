import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc, writeBatch, setDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import formatRUT from '../utils/format_rut';
import { capitalizeWords } from '../utils/capitalize';

export const createFamily = async (family) => {
    const batch = writeBatch(db);
    const childRefs = [];

    try {
        // 1. Crear el documento de la familia
        const familyRef = doc(collection(db, 'families'));
        await setDoc(familyRef, {
            alias: family.alias,
            dad: family.dad,
            mom: family.mom,
            address: family.address,
            createdAt: serverTimestamp(),
        });

        // 2. Crear documentos de los hijos y almacenar sus referencias
        for (const child of family.children) {
            const childRef = doc(collection(db, 'students'));
            childRefs.push(childRef.id); // Guardar el ID del documento de cada hijo
            batch.set(childRef, {
                name: child.name,
                lastName: child.lastName,
                familyId: familyRef.id,
            });
        }

        // 3. Hacer commit del batch para crear los documentos de los hijos
        await batch.commit();

        // 4. Actualizar el documento de la familia con los IDs de los hijos
        await updateDoc(familyRef, {
            children: childRefs,
        });

        return true;
    } catch (error) {
        console.error('Error creating family:', error);
        return false;
    }
};

export const getFamilies = async () => {
    try {
        const familiesCollection = collection(db, 'families');
        const q = query(familiesCollection, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);

        const families = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        console.log(families);
        return families;
    } catch (error) {
        console.error('Error fetching families:', error);
        console.log("Vacío");
        return [];
    }
};


export const getStudentsFromUnpaidFamilies = async () => {
    try {
        // Obtener las familias con estado de pago falso
        const familiesCollection = collection(db, 'families');
        const familiesQuery = query(familiesCollection, where('payed', '==', false));
        const familiesSnapshot = await getDocs(familiesQuery);

        const unpaidFamilyIds = familiesSnapshot.docs.map(doc => doc.id);
        const familiesData = familiesSnapshot.docs.map(doc => {
            return {
                name: doc.data().alias,
                id: doc.id,
                students: doc.data().children
            }
        });


        if (unpaidFamilyIds.length === 0) {
            return [];
        }

        // // Obtener los estudiantes que pertenecen a esas familias
        // const studentsCollection = collection(db, 'students');
        // const studentsQuery = query(studentsCollection, where('familyId', 'in', unpaidFamilyIds));
        // const studentsSnapshot = await getDocs(studentsQuery);

        // const students = studentsSnapshot.docs.map(doc => ({
        //     id: doc.id,
        //     ...doc.data(),
        // }));

        // const response = familiesSnapshot.docs.map(async doc => {
        //     return {
        //         name: doc.data().alias,
        //         id: doc.id,
        //         students: doc.data().children
        //     }
        // });

        return familiesData;
    } catch (error) {
        console.error('Error fetching students from unpaid families:', error);
        return [];
    }
};

export const getChildren = async (idFamily) => {
    console.log("Buscando hijos para el id de familia:", idFamily);
    try {
        const studentsCollection = collection(db, 'students');
        const q = query(
            studentsCollection,
            where('familyId', '==', idFamily)
        );
        const querySnapshot = await getDocs(q);

        console.log("Documentos encontrados:", querySnapshot.docs);

        const children = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return children;
    } catch (error) {
        console.error('Error al obtener los hijos:', error);
        return [];
    }
};
