import CryptoJS from 'crypto-js';

function getSignature(data, secretKey) {
    try {
        const sortedKeys = Object.keys(data).sort();
        let signature = '';

        sortedKeys.forEach((key) => {
            if (key.startsWith('x_')) {
                console.log(data[key]);
                signature += key + data[key].toString();
            }
        });

        const hmac = CryptoJS.HmacSHA256(signature, secretKey);
        const firmaHex = hmac.toString(CryptoJS.enc.Hex);

        return firmaHex;
    } catch (error) {
        console.log('Error getting signature:', error);
        return false;
    }
}

export default getSignature;