import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Box, Paper, Icon } from '@mui/material';
import { Search as SearchIcon, Payment as PaymentIcon, CheckOutlined, CheckCircleOutlineOutlined } from '@mui/icons-material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../database/config_firebase';
import salle from '../assets/salle_logo.png';
import colors from '../utils/colors';
import formatToClp from '../utils/format_to_clp';
import Swal from 'sweetalert2';
import loadingImg from '../assets/loading.svg';
import { generateRandomString } from '../utils/generate_string';
import getSignature from '../utils/get_signature';


const formatRut = (rut) => {
    // Eliminar puntos, guiones y espacios
    let cleanRut = rut.replace(/[\.\-]/g, '').trim().toUpperCase();

    // Validar largo mínimo de RUT (7 dígitos + 1 dígito verificador)
    if (cleanRut.length < 8) return null;

    // Obtener el dígito verificador
    const dv = cleanRut.slice(-1);
    // Obtener el número sin el dígito verificador
    const num = cleanRut.slice(0, -1);

    // Formatear el número con puntos
    const formattedNum = num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Retornar RUT formateado
    return `${formattedNum}-${dv}`;
};

const SearchPage = () => {
    const [rut, setRut] = useState('');
    const [debt, setDebt] = useState(null);
    const [loading, setLoading] = useState(false);
    const [idFamily, setIdFamily] = useState("");
    const [haveDeuda, setHaveDeuda] = useState(false)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const rutFromUrl = params.get('rut');
        if (rutFromUrl) {
            setRut(formatRut(rutFromUrl));
        }
    }, []);

    const handleSearch = async () => {
        const formattedRut = formatRut(rut);
        if (!formattedRut) {
            setDebt('RUT inválido. Por favor, verifica el RUT ingresado.');
            return;
        }

        setRut(formattedRut);

        setLoading(true);
        setDebt(null);

        try {
            const q = query(collection(db, 'families'), where('rutRepresent', '==', formattedRut));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const idFam = querySnapshot.docs[0].id;
                const doc = querySnapshot.docs[0].data();
                if (doc.payed === true) {
                    setDebt('No hay deudas registradas para este RUT.');
                } else {
                    console.log(doc, idFam);
                    setDebt(doc.amount);
                    setIdFamily(idFam)
                }
            } else {
                setDebt('No hay deudas registradas para este RUT.');
            }
        } catch (error) {
            console.error('Error al buscar deudas:', error);
        }

        setLoading(false);
    };

    const handlePayment = () => {
        let referenceID = `${idFamily}${generateRandomString(5)}`;
        console.log(referenceID);
        
        Swal.fire({
            imageUrl: loadingImg,
            imageWidth: 300,
            imageHeight: 300,
            title: "Creando el pago...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        const secretKey = "koGT79KqSy0lnwXnVxB8ARVLXBagFTa15AyYMH1dNLQAvdAOq9DseDWIZiB3YtcawcLHRDbzMrNGUeUES0IXvO0ogNUkkmhG7BGPTpnw1ZZw3jatAElhoVMcCYjZP7Nt";
        let document = {
            x_account_id: "03133479",
            x_amount: debt.toString(),
            x_currency: "CLP",
            x_customer_email: "",
            x_customer_first_name: "",
            x_customer_last_name: "",
            x_customer_phone: "",
            x_description: "Pago de colegio",
            x_reference: referenceID,
            x_shop_country: "CL",
            x_shop_name: "La Salle",
            x_url_callback: "https://www.lasalle.cl",
            x_url_cancel: "http://186.64.123.120:86/pendingPays?res=cancel",
            x_url_complete: "http://186.64.123.120:86/pendingPays?res=completed"
        };

        console.log(document);

        let signature = getSignature(document, secretKey);

        console.log("THIs is signature", signature);
        

        document.x_signature = signature;

        // const queryString = new URLSearchParams(document).toString();

        try {
            //https://us-central1-apoderados-fd064.cloudfunctions.net/payment
            //http://127.0.0.1:5001/apoderados-fd064/us-central1/payment
            fetch('https://us-central1-apoderados-fd064.cloudfunctions.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(document),
            })
                .then(response => response.json())
                .then(data => {
                    console.log("respuesta", data);
                    // setResponse(data);
                    if (data.url) {
                        console.log(data);
                        
                        window.location.replace(data.url);
                    }
                })
                .catch(error => console.error('Error:', error));
        } catch (error) {
            console.error('Error:', error);
        }


        console.log(signature);
    };

    return (
        <div style={{
            margin: '0',
            height: '100vh',
            backgroundColor: `${colors.blue}`,
            // backgroundImage: `${salle}`, /* URL del logo */
            backgroundRepeat: "repeat",
            backgroundSize: "100px 100px",/* Tamaño del logo */
            alignContent: 'center',
            alignItems: 'center'
        }}>
            <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={salle}
                    alt="Logo"
                    style={{ marginBottom: '0px', borderRadius: '10px', maxWidth: '300px' }}
                />
                <Paper elevation={0} style={{
                    padding: '20px',
                    width: '100%',
                    textAlign: 'center',
                    borderRadius: '15px',
                    boxShadow: '5px 5px 8px rgba(0, 0, 0, 0.6)',
                }}>
                    <Typography sx={{
                        marginBottom: '10px',
                        fontWeight: 'bold'
                    }} variant="h4" gutterBottom>
                        Paga tu deuda
                    </Typography>
                    <Typography sx={{
                        fontSize: '14px',
                        marginBottom: '20px'
                    }} gutterBottom>
                        Ingresa el rut del responsable económico de tu familia:
                    </Typography>
                    <TextField
                        // label="RUT"
                        variant="outlined"
                        placeholder='Rut responsable económico'
                        value={rut}
                        onChange={(e) => setRut(e.target.value)}
                        style={{ marginBottom: '20px', width: '80%' }}
                        InputProps={{
                            endAdornment: <SearchIcon color="primary" />,
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'primary.main',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'primary.light',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.dark',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: 'primary.main',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'primary.dark',
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSearch}
                        disabled={loading}
                        style={{
                            marginBottom: '20px',
                            fontSize: '16px',
                            textTransform: 'none',
                            boxShadow: 'none',
                            // height: '50px',
                            borderRadius: '20px', // Define el nivel de redondez
                            width: '80%', // Hace que el botón tenga el mismo ancho que el TextField
                        }}
                    >
                        Consultar deuda
                    </Button>

                    {loading && <Typography variant="body1">Cargando...</Typography>}
                    {debt !== null && (
                        <Box>
                            {debt == 'No hay deudas registradas para este RUT.' && (
                                <CheckCircleOutlineOutlined sx={{
                                    margin: 0,
                                    padding: 0,
                                    fontSize: '70px',
                                    color: colors.green
                                }} />
                            )}
                            {debt == 'No hay deudas registradas para este RUT.' ? (
                                <Typography variant="h6" style={{ marginTop: '20px', fontWeight: 'bold' }}>
                                    {debt}
                                </Typography>
                            ) : (
                                <Typography variant="h6" style={{ marginTop: '0px', fontWeight: 'bold' }}>
                                    {`Se ha encontrado una deuda pendiente de ${formatToClp(parseInt(debt))}`}
                                </Typography>
                            )}
                            {debt !== 'No hay deudas registradas para este RUT.' && (
                                <Button
                                    variant="contained"
                                    // color={colors.orange}
                                    onClick={handlePayment}
                                    disabled={loading}
                                    style={{
                                        backgroundColor: `${colors.orange}`,
                                        marginBottom: '10px',
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        textTransform: 'none',
                                        boxShadow: 'none',
                                        // height: '50px',
                                        borderRadius: '20px', // Define el nivel de redondez
                                        width: '80%', // Hace que el botón tenga el mismo ancho que el TextField
                                    }}
                                >
                                    Pagar
                                </Button>
                            )}
                        </Box>
                    )}
                </Paper>
            </Container>
        </div>
    );
};

export default SearchPage;
