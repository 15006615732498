import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import getSignature from '../utils/get_signature';
import Swal from 'sweetalert2';
import loadingImg from '../assets/loading.svg';
import { generateRandomString } from '../utils/generate_string';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



const PaymentModal = ({ open, handleClose, totalAmount, idFamily }) => {
    console.log(idFamily);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [response, setResponse] = useState({});

    const handlePay = async () => {
        Swal.fire({
            imageUrl: loadingImg,
            imageWidth: 300,
            imageHeight: 300,
            title: "Creando el pago...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        const secretKey = "koGT79KqSy0lnwXnVxB8ARVLXBagFTa15AyYMH1dNLQAvdAOq9DseDWIZiB3YtcawcLHRDbzMrNGUeUES0IXvO0ogNUkkmhG7BGPTpnw1ZZw3jatAElhoVMcCYjZP7Nt";
        let referenceID = `${idFamily}-${generateRandomString(10)}`;
        let document = {
            x_account_id: "03133479",
            x_amount: totalAmount.toString(),
            x_currency: "CLP",
            x_customer_email: email,
            x_customer_first_name: "",
            x_customer_last_name: "",
            x_customer_phone: "",
            x_description: "Pago de colegio",
            x_reference: referenceID,
            x_shop_country: "CL",
            x_shop_name: "La Salle",
            x_url_callback: "https://www.lasalle.cl",
            x_url_cancel: "http://localhost/pendingPays?res=cancel",
            x_url_complete: "http://localhost/pendingPays?res=completed"
        };

        console.log(document);

        let signature = getSignature(document, secretKey);

        document.x_signature = signature;

        // const queryString = new URLSearchParams(document).toString();

        try {
            //https://us-central1-apoderados-fd064.cloudfunctions.net/payment
            //http://127.0.0.1:5001/apoderados-fd064/us-central1/payment
            fetch('https://us-central1-apoderados-fd064.cloudfunctions.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(document),
            })
                .then(response => response.json())
                .then(data => {
                    console.log("respuesta", data);
                    // setResponse(data);
                    if (data.url) {
                        window.location.replace(data.url);
                    }
                })
                .catch(error => console.error('Error:', error));
        } catch (error) {
            console.error('Error:', error);
        }


        console.log(signature);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6" component="h2">
                        Pago
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="body1" gutterBottom>
                    Monto Total: ${totalAmount}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Datos de pago:
                </Typography>
                <TextField
                    fullWidth
                    label="Email"
                    value={email}
                    placeholder='example@123.cl'
                    onChange={(e) => setEmail(e.target.value)}
                    // InputProps={{ readOnly: true }}
                    variant="outlined"
                    margin="normal"
                />
                <Typography variant="body1" gutterBottom sx={{
                    marginBottom: 3
                }}>
                    Si necesitas usar transferencia puedes usar los siguientes datos:
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Banco: Banco Estado
                </Typography>
                <Typography variant="body1" gutterBottom>
                    N° de cuenta: 123456789
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Rut: 12.345.678-9
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Nombre: Nombre Apellido
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Correo: pagos@maxipos.cl
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handlePay}
                    sx={{ mt: 2 }}
                >
                    Pagar por WebPay
                </Button>
            </Box>
        </Modal>
    );
};

export default PaymentModal;
