import React, { useState } from 'react';
import ReusableDrawer from '../components/drawer';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, Tooltip, Legend, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Typography, Box } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import colors from '../utils/colors';
import formatToClp from '../utils/format_to_clp';

export default function Home() {
  const [drawerOpen, setDrawerOpen] = useState(true);

  const data = [
    { name: 'Enero', pendientes: 4000, delMes: 2400, totalRecaudado: 2400, mesRecaudado: 2400 },
    { name: 'Febrero', pendientes: 3000, delMes: 1398, totalRecaudado: 2210, mesRecaudado: 2210 },
    { name: 'Marzo', pendientes: 2000, delMes: 9800, totalRecaudado: 2290, mesRecaudado: 2290 },
    { name: 'Abril', pendientes: 2780, delMes: 3908, totalRecaudado: 2000, mesRecaudado: 2000 },
    { name: 'Mayo', pendientes: 1890, delMes: 4800, totalRecaudado: 2181, mesRecaudado: 2181 },
    { name: 'Junio', pendientes: 2390, delMes: 3800, totalRecaudado: 2500, mesRecaudado: 2500 },
    { name: 'Julio', pendientes: 3490, delMes: 4300, totalRecaudado: 2100, mesRecaudado: 2100 },
  ];

  const COLORS = [colors.blue, colors.blueLight, colors.greenAqua, colors.yellow];
  const totalRecaudado = data.reduce((acc, curr) => acc + curr.totalRecaudado, 0);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      width: '100vw',
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'home'} />
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px',
          width: '80%',
        }}>
          <div style={{
            width: '45%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: 'white',
          }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'center',
            }}>Pagos pendientes</h2>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pendientes" stroke={colors.blue} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div style={{
            width: '45%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: 'white',
          }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'center',
            }}>Pagos del mes</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="delMes" fill={colors.blue} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '80%',
        }}>
          <div style={{
            width: '45%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: 'white',
          }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'center',
            }}>Cantidad total recaudada</h2>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={300}>
              <AttachMoneyIcon style={{ fontSize: 80, color: colors.greenAqua }} />
              <Typography variant="h4" style={{ color: colors.greenAqua }}>
                {formatToClp(totalRecaudado)}
              </Typography>
            </Box>
          </div>
          <div style={{
            width: '45%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: 'white',
          }}>
            <h2 style={{
              color: colors.blue,
              textAlign: 'center',
            }}>Cantidad del mes recaudada</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="mesRecaudado" fill={colors.greenAqua} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  )
}
