const colors = {
    blue: '#06314e',
    blueLight: '#005b77',
    greenAqua: '#00878d',
    yellow: '#e9c704',
    green: '#0C9A41',
    greenBackground: '#0C9A4133',
    border: '#1212121F',
    grey: '#e3e2e3',
    background: '#03346E',
    greyBack: '#f6f6f8',
    red: '#BD0000',
    orange: 'orange',
    white: 'white',
    black: 'black',
}

export default colors;