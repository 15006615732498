import './App.css';
import Login from './pages/login';
import Home from './pages/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Families from './pages/families';
import Students from './pages/students';
import PendingPays from './pages/pending_pays';
import SearchPage from './pages/public_pay';

function App() {

  const logged = useSelector((state) => state.auth.logged);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={logged ? <Home /> : <Login />} />
        <Route path="/home" element={logged ? <Home /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/families" element={<Families />} />
        <Route path="/students" element={<Students />} />
        <Route path="/pendingPays" element={<PendingPays />} />
        <Route path="/pay" element={<SearchPage />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;

