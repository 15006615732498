import React, { useEffect, useState } from 'react';
import ReusableDrawer from '../components/drawer';
import AddFamilyModal from '../components/family_modal';
import { getFamilies } from '../database/family';
import loadingImg from '../assets/loading.svg';
import { Box, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Pagination, Fab, TextField, InputAdornment, Avatar } from '@mui/material';
import colors from '../utils/colors';
import { ArrowForwardIos, Search, Add } from '@mui/icons-material';
import { capitalize, capitalizeWords } from '../utils/capitalize';

export default function Families() {
    const [modalOpen, setModalOpen] = useState(false);
    const [families, setFamilies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFamilies, setFilteredFamilies] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState({});

    const [isView, setIsView] = useState(false)

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedFamilies = filteredFamilies.slice(startIndex, endIndex);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    async function getRequests() {
        setLoading(true);
        try {
            const response = await getFamilies();
            setFamilies(response);
        } catch (error) {
            console.error('Error fetching families:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getRequests();
    }, []);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = families.filter(family =>
            family.alias && family.alias.toLowerCase().includes(lowercasedFilter)
        );
        setFilteredFamilies(filteredData);
        setPage(1); // Reset page to 1 when search term changes
    }, [searchTerm, families]);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const [drawerOpen, setDrawerOpen] = useState(true);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            width: '100vw',
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'families'} />
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
                    <p>Cargando familias...</p>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                    position: 'relative'
                }}>
                    <ListItem sx={{ paddingX: 5 }}>
                        <ListItemText sx={{ color: colors.green }} primary={`Todos las familias (${families.length})`}
                            primaryTypographyProps={{
                                fontFamily: 'Nunito',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                color: colors.blue,
                                textAlign: 'center'
                            }} />
                    </ListItem>
                    <TextField
                        placeholder="Buscar familia por apellido"
                        variant='outlined'
                        sx={{
                            width: '90%',
                            mb: 1,
                            backgroundColor: colors.greyBack,
                            borderRadius: '50px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {paginatedFamilies.map((family) => (
                        <ListItem
                            onClick={() => {
                                setIsView(true)
                                setSelectedFamily(family);
                                setModalOpen(true)
                            }}
                            key={family.id} sx={{
                                cursor: 'pointer',
                                margin: '2px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '90%',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '10px',
                                border: '1px solid #1212121F',
                            }}>
                            <ListItemAvatar>
                                <Avatar sx={{ backgroundColor: colors.blue }}>
                                    {family.alias ? capitalize(family.alias).substring(0, 1) : ''}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ ml: 1 }}
                                primaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                secondaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                primary={`Familia ${capitalizeWords(family.alias)}` || 'Sin alias'}
                                secondary={`${family.children.length} hijos - ${capitalizeWords(family.address)}` }
                            />
                            <ListItemIcon>
                                <ArrowForwardIos />
                            </ListItemIcon>
                        </ListItem>
                    ))}
                    <Pagination
                        count={Math.ceil(filteredFamilies.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        sx={{ mt: 2 }}
                    />
                </Box>
            )}
            <Fab
                color="primary"
                aria-label="add"
                onClick={handleOpen}
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <Add />
            </Fab>
            <AddFamilyModal open={modalOpen} handleClose={handleClose} reloadData={getRequests} isView={isView} data={selectedFamily} />
        </div>
    );
}
