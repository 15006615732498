import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Typography, IconButton, Divider } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import colors from '../utils/colors';
import { createFamily, getChildren } from '../database/family';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const AddFamilyModal = ({ open, handleClose, reloadData, isView, data }) => {
    const [family, setFamily] = useState({
        alias: '',
        dad: '',
        mom: '',
        address: '',
        children: [{ name: '', lastName: '' }],
    });

    const handleChange = (e, index, field) => {
        const value = e.target.value;
        if (field === 'name' || field === 'lastName') {
            const newHijos = [...family.children];
            newHijos[index][field] = value;
            setFamily({ ...family, children: newHijos });
        } else {
            setFamily({ ...family, [field]: value });
        }
    };

    const addHijo = () => {
        setFamily({ ...family, children: [...family.children, { name: '', lastName: '' }] });
    };

    const removeHijo = (index) => {
        const newHijos = family.children.filter((_, i) => i !== index);
        setFamily({ ...family, children: newHijos });
    };

    const handleSave = async () => {
        const res = await createFamily(family)
        if (res) {
            handleClose();
            reloadData();
            setFamily({
                alias: '',
                dad: '',
                mom: '',
                address: '',
                children: [{ name: '', lastName: '' }],
            });
        } else {
            Swal.fire({
                title: 'Error',
                icon: 'error',
                text: "No se ha podido crear la familia. Inténtalo nuevamente."
            })
        }
    };

    useEffect(() => {
        async function initData() {
            if (isView) {
                console.log(isView, data);
                const res = await getChildren(data.id);
                let children = []
                console.log("Children", res);
                res.map((child) => {
                    children.push({
                        name: child.name,
                        lastName: child.lastName
                    })
                })
                console.log(children);
                setFamily({
                    alias: data.alias,
                    dad: data.dad,
                    mom: data.mom,
                    address: data.address,
                    children: children
                })
                console.log("Family", family);
            }
        }

        initData();
    }, [data])


    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" component="h2" style={{ marginBottom: '16px', color: colors.blue }}>
                    {
                        isView ? 'Editar Familia' : 'Agregar Familia'
                    }
                </Typography>
                <TextField
                    label="Alias de Familia"
                    fullWidth
                    value={family.alias}
                    onChange={(e) => handleChange(e, null, 'alias')}
                    style={{ marginBottom: '16px' }}
                />
                <TextField
                    label="Padre de Familia"
                    fullWidth
                    value={family.dad}
                    onChange={(e) => handleChange(e, null, 'dad')}
                    style={{ marginBottom: '16px' }}
                />
                <TextField
                    label="Madre de Familia"
                    fullWidth
                    value={family.mom}
                    onChange={(e) => handleChange(e, null, 'mom')}
                    style={{ marginBottom: '16px' }}
                />
                <TextField
                    label="Dirección"
                    fullWidth
                    value={family.address}
                    onChange={(e) => handleChange(e, null, 'address')}
                    style={{ marginBottom: '16px' }}
                />
                <Typography variant="body1" component="p" style={{ marginBottom: '8px', color: colors.blue }}>
                    Hijos
                </Typography>
                {family.children.map((child, index) => (
                    <Box key={index} display="flex" alignItems="center" marginBottom="8px">
                        <TextField
                            label="Nombre"
                            value={child.name}
                            onChange={(e) => handleChange(e, index, 'name')}
                            style={{ marginRight: '8px' }}
                        />
                        <TextField
                            label="Apellido"
                            value={child.lastName}
                            onChange={(e) => handleChange(e, index, 'lastName')}
                            style={{ marginRight: '8px' }}
                        />
                        {family.children.length > 1 && (
                            <IconButton onClick={() => removeHijo(index)}>
                                <Remove />
                            </IconButton>
                        )}
                    </Box>
                ))}
                <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={addHijo}
                    style={{ marginBottom: '16px', color: colors.blue, borderColor: colors.blue }}
                >
                    Agregar Hijo
                </Button>
                <Divider style={{ marginBottom: '16px' }} />
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSave}
                    style={{ backgroundColor: colors.blue, color: colors.white }}
                >
                    Guardar
                </Button>
            </Box>
        </Modal>
    );
};

export default AddFamilyModal;