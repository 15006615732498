import { collection, getDocs } from 'firebase/firestore';
import { db } from './config_firebase';

export const getStudents = async () => {
    try {
        const studentsCollection = collection(db, 'students');
        const querySnapshot = await getDocs(studentsCollection);

        const students = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return students;
    } catch (error) {
        console.error('Error fetching students:', error);
        return [];
    }
};